import { Component, OnInit } from '@angular/core';
import { GlobalVariable } from '../global';
import { ServerService } from '../providers/server.service';
import { AlertController, ModalController, NavController, Platform, ToastController } from '@ionic/angular';
@Component({
  selector: 'app-stock-out',
  templateUrl: './stock-out.page.html',
  styleUrls: ['./stock-out.page.scss'],
})
export class StockOutPage implements OnInit {
  StockoutItems = [];
  isItemSelected=false;
  type : any = "app";
  constructor(public globals:GlobalVariable,
    private server:ServerService,
    private modalCtrl : ModalController,
    private nav : NavController) {
      this.globals.isDataLoaded=false;
      this.getOutOfStockItems()
     }

  ngOnInit() {
    this.globals.isDataLoaded=false;
    this.getOutOfStockItems()
  }

  ionViewWillEnter(){
    this.globals.isDataLoaded=false;
    this.getOutOfStockItems()
  }

  close(){
    this.modalCtrl.dismiss();
    this.nav.pop()
  }

  getOutOfStockItems(){
    let response = this.server.getOutOfStockList();
    response.subscribe(res => {
    this.globals.isDataLoaded=true;
      // console.log(res)
      if (res.success) {
        if(this.type == "app"){
          this.StockoutItems = res.items;
        }else{
          this.StockoutItems = res.chowly_items;
        }
        // this.StockoutItems = res.data;
      }
    }, error => {
      this.globals.showToast("Something went wrong check your internet connection.",'danger')

    })
  }

  updateStockoutItemsHandler() {
    if(this.isItemSelected){
    // let loading = this.loadingCtrl.create({
    //   content: "Loading...",

    // });
    // loading.present();
    let response = this.server.updateOutOfStockList(this.StockoutItems);

    response.subscribe(res => {
      // console.log(res)
      // loading.dismiss();
      this.globals.showToast(res.message,'success')
      if (res.success) {
        this.isItemSelected=false;
        this.getOutOfStockItems()
        // this.navCtrl.pop();
      }

    }, error => {
      // loading.dismiss();
      this.globals.showToast("Something went wrong check your internet connection.",'danger')

    })
  }
  else{
    this.globals.showToast('Select items to update','warning')
  }
  }
  checkvalues(e){
    this.isItemSelected=e.detail.checked
// console.log(e.detail.checked)
  }

  pushChowly(){
    this.globals.loader()
    this.server.pushToChowly().subscribe(res=>{
      this.globals.dismiss();
      console.log(res);
      this.globals.showToast(res.message,'dark',2000,'middle')
    },err=>{
      this.globals.dismiss();
    })
  }
}
