import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { GlobalVariable } from '../global';

@Component({
  selector: 'app-date-selection',
  templateUrl: './date-selection.page.html',
  styleUrls: ['./date-selection.page.scss'],
})
export class DateSelectionPage  {
  dateNow
  selectedDate: any;
  selectedTime: any;
  @Input('dateTime')dateTime

  constructor(private modalController: ModalController,private globals:GlobalVariable) {}

  ionViewWillEnter() {
   this.dateNow= new Date().toISOString();
    // If there are existing selected date and time, populate them
    // const { selectedDate, selectedTime } = this.dateTime
    // this.selectedDate = selectedDate || null;
    // this.selectedTime = selectedTime || null;
  }
  close(){
    this.modalController.dismiss()
  }

  dismissModal() {
    console.log(this.selectedDate ,this.selectedTime)
    if(!this.selectedDate && !this.selectedTime ){
      this.globals.showToast('Please Select Date & Time','dark')
      return;

    }

    let selectedDate
    if(this.selectedDate){
     selectedDate = new Date(this.selectedDate).toLocaleDateString('en-us');
    }
    else{
      this.globals.showToast('Please Select Date','dark')
      return
    }

   let selectedTime
   if(this.selectedTime){
    selectedTime = new Date(this.selectedTime).toLocaleTimeString('en-us',);
   }
   else{
    this.globals.showToast('Please Select Time','dark')
    return
   }

    // Pass the selected date and time back to the parent page
    this.modalController.dismiss({
      selectedDate: selectedDate,
      selectedTime: selectedTime,
    });
  }
}