import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { GlobalUserServiceService } from '../services/global-user-service.service';

@Component({
  selector: 'app-global-model',
  templateUrl: './global-model.component.html',
  styleUrls: ['./global-model.component.scss'],
})
export class GlobalModelComponent implements OnInit {

  simpleModel:any;
  deletionModel:any;
  @Output() confirmClicked: EventEmitter<void> = new EventEmitter<void>();

    onClickConfirm() {
      this.confirmClicked.emit();
    }

  constructor(private glogalUserService:GlobalUserServiceService) { 
    
  }

  ngOnInit() {
    
      this.glogalUserService.GlobalModelSubject.subscribe((data)=>{
        if(data?.model=== 'process'){
          this.deletionModel=false;
          this.simpleModel=true;
        }else if(data?.model=== 'deletionConfirmation'){
          this.simpleModel=false;
          this.deletionModel=true;
        }
      });
    
  
  }

  CloseModel(){
    this.glogalUserService.updateglobalModel({state:false,model:"eletionConfirmation"});
  }

  ngDestroy(){
    this.glogalUserService.updateglobalModel(false);
  }

}
