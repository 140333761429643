import { Injectable } from '@angular/core';
import { BluetoothSerial } from '@awesome-cordova-plugins/bluetooth-serial/ngx';
@Injectable({
  providedIn: 'root'
})
export class PrinterServiceService {

  constructor(private bluetoothSerial :BluetoothSerial) { 
    // console.log('bluetooth service');
  }
  enableBluetooth(){
    return this.bluetoothSerial.enable();
  }
discoverUnpaired(){
  return this.bluetoothSerial.discoverUnpaired();
}
  searchBluetooth(){
    return this.bluetoothSerial.list();
  }

  connectBluetooth(address){
    // debugger
    return this.bluetoothSerial.connect(address);
  }

  printData(data){
    console.log(data, "Data yeh a rha hae")
    return this.bluetoothSerial.write(data);
  }

  disconnectBluetooth(){
    return this.bluetoothSerial.disconnect();
    // this.bluetoothSerial.
  }
}
