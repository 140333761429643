import { Injectable } from '@angular/core';
import { NativeAudio } from '@ionic-native/native-audio/ngx';
import { GlobalVariable } from 'src/app/global';

@Injectable({
  providedIn: 'root'
})
export class RingtoneService {

  constructor(private nativeAudio:NativeAudio,
    private globals:GlobalVariable) { 
      // this.nativeAudio.preloadSimple(this.globals.alertSound,'assets/sound/'+this.globals.alertSound+'.mp3').then(onSuccess=>{
      //   console.log('success',onSuccess)
      // },
      // onError=>{
      //   console.log('error',onError)
      //  });
    }


  ringOnce() {
    // console.log('ring once')
    // this.nativeAudio.preloadSimple(this.globals.alertSound,'assets/sound/'+this.globals.alertSound+'.mp3').then(onSuccess=>{
    //   console.log('success',onSuccess)
      this.nativeAudio.play(this.globals.alertSound).then(res=>{
        // console.log(res,'sound played')
      //   setTimeout(()=>{
      //   this.nativeAudio.unload(this.globals.alertSound)
      // },4000)
        // this.globals.showToast('Notification Sound Selected','success')
      },err=>{
        // console.log('failed to play',err)
      })

    // },
    // onError=>{
    //   console.log('error',onError)
    //  });
 
  }

  continuesRing() {
    // console.log('loop ringing')
    // this.nativeAudio.preloadSimple(this.globals.alertSound,'assets/sound/'+this.globals.alertSound+'.mp3').then(onSuccess=>{
    //   console.log('success',onSuccess)
      this.nativeAudio.loop(this.globals.alertSound).then(res=>{
        // console.log(res,'sound played')
        // setTimeout(()=>{
        //   this.nativeAudio.unload(this.globals.alertSound)
        // },4000)
        // this.nativeAudio.unload(this.globals.alertSound)
        // this.globals.showToast('Notification Sound Selected','success')
      },err=>{
        // console.log('failed to play',err)
      })
      
    // },
    //  onError=>{
    //    console.log('error',onError)
    //   });
    // this.nativeAudio.loop('clickSound_newOrder').then((data) => {
    //   // console.log("Audio Loop then Listing", data)
    // });
    
  }

  stopRing() {
    // console.log('stop ringing')
    // this.nativeAudio.preloadSimple(this.globals.alertSound,'assets/sound/'+this.globals.alertSound+'.mp3').then(onSuccess=>{
    //   console.log('success',onSuccess)
      this.nativeAudio.stop(this.globals.alertSound).then(res=>{
        // console.log(res,'sound played')
        // this.nativeAudio.unload(this.globals.alertSound)
        // this.globals.showToast('Notification Sound Selected','success')
      },err=>{
        // console.log('failed to play',err)
      })
      
    // },
    //  onError=>{
    //    console.log('error',onError)
    //   });
    // this.nativeAudio.stop('clickSound_newOrder').then((data) => {
    //   // console.log("Stop Audio Loop then ", data)
    // });
   
  }
}
