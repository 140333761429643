import { Injectable } from '@angular/core';
import { NativeStorage } from '@ionic-native/native-storage/ngx';
import { StarPRNT } from '@ionic-native/star-prnt/ngx';
import { AlertController, Platform, ToastController } from '@ionic/angular';
import { GlobalVariable } from '../global';
import { commands } from '../printer-commands';
import { PrinterServiceService } from './printer-service.service';
import { Vibration } from '@ionic-native/vibration/ngx';
import * as moment from 'moment';
import { RingtoneService } from './ringtone/ringtone.service';
import { BluetoothSerial } from '@awesome-cordova-plugins/bluetooth-serial/ngx';
// import { AndroidPermissions } from '@awesome-cordova-plugins/android-permissions/ngx';

@Injectable({
  providedIn: 'root'
})
export class PrintNowService {
  refreshIntervalId: any;
  // ordersPrinted: any;
  extra_category_title_print: any;
  ProductsTotal: any=0;
  discountTotal: any=0;
  giftcard_count:any
  errorConnecting = "There was an error connecting to the printer, please try again!";
  errorPrinting = "There was an error printing, please try again!";
  printSuccess = "Successful print!";
  total: number;
  tax_calc: string;
  // for starprint
  line1 = '___________________________\n';
  line2 = '---------------------------\n';
  line3 = '===========================\n';
  line4 = '- - - - - - - - - - - - - -\n';
  businessDiscount = "";
  notes = "";
  pointUsed = "";
  tipInclud = "";
  interval_count = 1;
  interval_strPRNT;
  toast
  orders: any;
  newInterval:any;
  lastPrinted: boolean=false;
  i: number=0
  l_l: number=0
  u_l: number=5
  // i: number=0;
  // for star pnt ends

  constructor(private globals:GlobalVariable,
    private toastCtrl:ToastController,
    private alertCtrl:AlertController,
    private printService:PrinterServiceService,
    private starprnt:StarPRNT,
    private nativeStorage:NativeStorage,
    private vibration: Vibration,
    private ringtone:RingtoneService,
    public platform:Platform,
    private bluetooth:BluetoothSerial
    // private androidP:AndroidPermissions
    
    ) { 
// console.log('...........in printing service.............')

     
    }
  bluetooth_printing_segmentation(order) {
    this.globals.showToast('Hold on..','dark',2000,'middle');
    this.lastPrinted=false;
    this.globals.check=true;
    console.log(order,'order to print now');
    console.log('in segmentation');
   
  

    var that = this;
    this.orders=JSON.parse(JSON.stringify(order)); //was causing invalid order data.. solved with stringify and thn parse array
    this.i = 0;
    var limit = this.orders.order_data.length / 4;   //15/9 =1.8 7 34/9=3.7 34/4 8 worked at 4 11
    console.log('limit before floor',limit),' i is ',this.i;
    limit = Math.floor(limit) //  if 5 orders 34/9 =3 after flooring;
    console.log('limit after floor',limit);



    this.l_l = 0;
    this.u_l = 4;

 // if (that.globals.check == true) {
  // console.log('scheck for something => ',this.globals.check)
  // this.globals.check = false;
  // this.newInterval=setInterval(()=>{
    // if(this.globals.check==true && this.lastPrinted==false){
    //   this.globals.check=false;


      this.refreshIntervalId = setInterval(()=> {
        if(this.globals.check==true && this.lastPrinted==false){
          this.globals.check=false;
        console.log('i is',this.i)
      var order_d = JSON.parse(JSON.stringify(this.orders));
      console.log('in segmentation... 1',limit,'limit ',this.orders.order_data.length);
  
        if (this.i == 0) {  //first time i is zero
      console.log('in segmentation firstt time');
  
          if (this.i == limit) { //0==8
      console.log('in segmentation limit reched',limit);
  
      that.prepareToPrint(order_d,this.i,limit);
  
            // final call
            this.lastPrinted=true;
            clearInterval(this.refreshIntervalId);
          } else {
      console.log('in segmentation not = lmit');
  
  
            var sliced = order_d.order_data.slice(this.l_l, this.u_l);
            order_d.order_data=sliced
  
            console.log(order_d.order_data.length,'sliced items',sliced)
            // order.order_data=p1;
            this.l_l += 4; //0+8 =8
            this.u_l += 4; //8+8 =16
            that.prepareToPrint(order_d,this.i,limit);
  
          }
          this.i++;
          // this.globals.showToast('segment change'+ this.i,'danger',500,'top')
          console.log(this.i,' increasing i.....')
  
        } else {
         
            if (this.i == limit) {
              console.log('last ...........');
              // tslint:disable-next-line:no-duplicate-variable
              var p1 = order_d.order_data.slice(this.l_l, order_d.order_data.length);
            order_d.order_data=p1
  
            console.log('order length ',p1.length,order_d.order_data.length)
  
              // order.order_data=p1
  
              that.prepareToPrint(order_d,this.i,limit);
  
  
              // final call
            this.lastPrinted=true;

              clearInterval(this.refreshIntervalId);
            } else {
              console.log('not last');
  
              // tslint:disable-next-line:no-duplicate-variable
              var p1 = order_d.order_data.slice(this.l_l, this.u_l);
            order_d.order_data=p1
  
            console.log('order length segment',p1.length,'total length',order_d.order_data.length)
  
  
              // order.order_data=p1
  
              this.l_l += 4;
              this.u_l += 4;
              that.prepareToPrint(order_d,this.i,limit);
            }
            this.i++;
          // this.globals.showToast('segment change'+ this.i,'danger',500,'top')

          // }
        }


      }
      else{
        console.log('cannot print')
      }

      }, 3000); //old 2000  new 2500 




    // }


  // },1000)

    
  }

  async preparePrintMessage(data,autoPrintMsgCheck?) {
   
    
   data=JSON.parse(JSON.stringify(data)); 

    if(data.created_at){
    var dateFormat = moment(data.created_at).format('MMM DD, YYYY');
    var timeFormat = moment(data.created_at).format('h:mm a');
    }
    this.ringtone.ringOnce();
     
    let receipt = '';

    
    receipt += commands.EOL;
    receipt += commands.EOL;
    //phone
    receipt += commands.TEXT_FORMAT.TXT_NORMAL;
    receipt += commands.TEXT_FORMAT.TXT_ALIGN_LT;
    receipt += 'Phone: '+ data.From;
    receipt += commands.EOL;

    //date
    receipt += 'Date : '+ dateFormat;
    receipt += commands.EOL;

    //time
    receipt += 'Time : '+ timeFormat;

    receipt += commands.EOL;
    receipt += commands.EOL;

    //phone
    receipt += commands.TEXT_FORMAT.TXT_NORMAL;
    receipt += commands.TEXT_FORMAT.TXT_4SQUARE
    receipt += commands.TEXT_FORMAT.TXT_ALIGN_LT;
    receipt += commands.TEXT_FORMAT.TXT_BOLD_OFF
    receipt += 'Ph: '+ data.From;
    receipt += commands.EOL;


    //message
    receipt += commands.TEXT_FORMAT.TXT_4SQUARE
    receipt += commands.TEXT_FORMAT.TXT_FONT_B
    receipt += commands.TEXT_FORMAT.TXT_BOLD_ON
    receipt += 'Msg: '+ data.Body;
    receipt += commands.EOL;

    //secure space on footer
    receipt += commands.TEXT_FORMAT.TXT_NORMAL;
    receipt += commands.TEXT_FORMAT.TXT_BOLD_OFF
    receipt += commands.TEXT_FORMAT.TXT_ALIGN_CT

    receipt += '___________________________________________';
    receipt += commands.EOL;
    receipt += '___________________________________________';
    receipt += commands.EOL;
    receipt += commands.EOL;
    receipt += commands.EOL;
    receipt += commands.EOL;
    receipt += commands.EOL;
    receipt += commands.PAPER.PAPER_PART_CUT;
    console.log(receipt)


    this.vibration.vibrate(200);
    
    // let p_type=localStorage.getItem('printerType')

    //   if(p_type!=null && p_type!=undefined){

    //     this.globals.printerTYPE=p_type

    // }

    if (this.globals.printerTYPE == 'regular') {
      this.printMessage(receipt,data,autoPrintMsgCheck)
    } 
  }

  async prepareToPrint(order,inc,limit) {
    console.log(order,'order to print now  inc and limit',inc,limit)

    // this.anothertotal(order);
    this.Total(order);
    var order_type: String;
    var tab = '    ';
    var space_up = '      '
    if (order.instructions.Type == 'delivery' || order.instructions.Type == 'Delivery' || order.instructions.Type == 'DELIVERY') {
      order_type = "DELIVERY"
    }
    else {
      order_type = "PICKUP"
    }
    // for image ...test
    // let image ="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAIAAAAlC+aJAAAA3ElEQVR4nOzRoWpCcRiG8TH+rK0PFjbGwsKBs7gLGGNhd7BzAbuJ4RXYxOg1GAWLQQxiUkwGo8mizSBew5seDzy//H7h4SvN5P0mUT10o/3irRPtNz9NtL+N1lfIAJoBNANoBtAMoBlAM4BWDuO76GA+Gkb7r/U52p+qOtq3/gMG0AygGUAzgGYAzQCaAbTy/fQRHbzMttF++vwb7QfLVbRv/QcMoBlAM4BmAM0AmgE0A2jl/3gfHfzVu2j/ue9H+8fea7Rv/QcMoBlAM4BmAM0AmgE0A2iXAAAuuuu8uu=="
    // let IMAGE_FORMAT='';
    //  IMAGE_FORMAT+=commands.IMAGE_FORMAT.S_RASTER_N
    //  IMAGE_FORMAT += image
     // for image ...test

     order.order_data.forEach(orderItems=>{
      if(orderItems.menuExtrasSelected.length>0){
      orderItems.menuExtrasSelected.forEach(option=>{
        // console.log(option)
        if(option.hasOwnProperty('options')){
          // console.log('has property', option.hasOwnProperty('options'));
          option.optionNameSelected=option.options
        }
      })
    }
    })
     
    let receipt = '';
    // var image = new Image();

    // var img = new Image();
    // img.width=50;
    // img.height=50;
    // img.src = "https://cdn.pixabay.com/photo/2017/01/03/02/07/vine-1948358__480.png"
    // receipt += commands.IMAGE_FORMAT.S_RASTER_2W
    // receipt += commands.IMAGE_FORMAT.S_RASTER_2H
    // receipt += img


//conditions starts here
if(inc==0){ //i is zero .. first time here
  
    
    receipt += commands.EOL;
    receipt += commands.TEXT_FORMAT.TXT_4SQUARE
    receipt += commands.TEXT_FORMAT.TXT_FONT_B
    receipt += commands.TEXT_FORMAT.TXT_BOLD_ON
    receipt += commands.TEXT_FORMAT.TXT_ALIGN_CT
    receipt += tab;
    // receipt += space_up;
    receipt += this.globals.name
    receipt += commands.EOL;


    // receipt += commands.TEXT_FORMAT.TXT_NORMAL;
    // // receipt += tab;
    // receipt += "babylon new york";
    // receipt += commands.EOL;

    // receipt += commands.TEXT_FORMAT.TXT_NORMAL;
    // // receipt += tab;
    // receipt += "034038383838";
    // receipt += commands.EOL;
    
    receipt += commands.HARDWARE.HW_INIT;
    receipt += commands.TEXT_FORMAT.TXT_ALIGN_CT;
    receipt += commands.TEXT_FORMAT.TXT_BOLD_ON;
    receipt +=  (order.printType ? '[' + order.printType + ']' + commands.EOL : '');
    receipt += commands.EOL;
    // receipt += commands.TEXT_FORMAT.TXT_ALIGN_LT;
    receipt += commands.TEXT_FORMAT.TXT_ALIGN_CT

    receipt += "Order type:  "+order_type;
    receipt += commands.EOL;

    

    receipt += commands.EOL;
    receipt += commands.MARGINS.BOTTOM;
    // receipt += commands.EOL;
    // receipt += commands.TEXT_FORMAT.TXT_2HEIGHT;

    receipt += commands.TEXT_FORMAT.TXT_NORMAL;
    receipt += commands.EOL;
    receipt += commands.HORIZONTAL_LINE.HR3_58MM;
    receipt += commands.EOL;

    receipt += commands.TEXT_FORMAT.TXT_4SQUARE
    receipt += commands.TEXT_FORMAT.TXT_FONT_B
    receipt += commands.TEXT_FORMAT.TXT_BOLD_ON
    receipt += commands.TEXT_FORMAT.TXT_ALIGN_CT
    receipt += tab;
    // receipt += space_up;
    receipt += "Customer Details"
    receipt += commands.EOL;

    receipt += commands.TEXT_FORMAT.TXT_NORMAL;
    receipt += commands.EOL;
    receipt += commands.HORIZONTAL_LINE.HR3_58MM;
    receipt += commands.EOL;


    // receipt += commands.TEXT_FORMAT.TXT_NORMAL;
    // receipt += commands.MARGINS.LEFT;
    // receipt += "Customer Name";
    // receipt += commands.EOL;
   
    // receipt += commands.TEXT_FORMAT.TXT_BOLD_ON;
    receipt += commands.TEXT_FORMAT.TXT_4SQUARE;
    receipt += commands.TEXT_FORMAT.TXT_FONT_B
    receipt += commands.MARGINS.LEFT;
    receipt += order.customer_name.toUpperCase();
    receipt += commands.EOL;

    receipt += commands.TEXT_FORMAT.TXT_NORMAL;
    // receipt += commands.TEXT_FORMAT.TXT_BOLD_ON;
    receipt += "Customer Phone: "+order.customer_phone;
    receipt += commands.EOL;
   

    receipt += "Customer ID: " + order.customer_id;
    receipt += commands.EOL;
    // receipt += commands.EOL;
    // receipt += commands.EOL;

    receipt += commands.TEXT_FORMAT.TXT_NORMAL;
    receipt += commands.EOL;
    receipt += commands.HORIZONTAL_LINE.HR3_58MM;
    receipt += commands.EOL;

// sb good hai 
    receipt += commands.TEXT_FORMAT.TXT_4SQUARE
    receipt += commands.TEXT_FORMAT.TXT_FONT_B
    receipt += commands.TEXT_FORMAT.TXT_BOLD_ON
    receipt += commands.TEXT_FORMAT.TXT_ALIGN_CT
    receipt += tab;
    // receipt += space_up;
    receipt += "Order Details"
    receipt += commands.EOL;

    receipt += commands.TEXT_FORMAT.TXT_NORMAL;
    receipt += commands.EOL;
    receipt += commands.HORIZONTAL_LINE.HR3_58MM;
    receipt += commands.EOL;


    receipt += commands.TEXT_FORMAT.TXT_NORMAL;
    receipt += commands.TEXT_FORMAT.TXT_BOLD_OFF;
    receipt += "Pickup Time";
    receipt += commands.EOL;

    receipt += commands.TEXT_FORMAT.TXT_4SQUARE;
    receipt += order.scheduled_time;
    receipt += commands.EOL;

    receipt += commands.TEXT_FORMAT.TXT_NORMAL;
    receipt += "Order Number";
    receipt += commands.EOL;

    receipt += commands.TEXT_FORMAT.TXT_4SQUARE;
    receipt += order.order_number;
    receipt += commands.EOL;
    // receipt += commands.EOL;

    receipt += commands.TEXT_FORMAT.TXT_NORMAL;
    receipt += commands.TEXT_FORMAT.TXT_BOLD_ON;
    // receipt += order.customer_phone;
    // receipt += commands.EOL;

if(order_type!='PICKUP'){
  receipt += "Address:  "+order.address;
  receipt += commands.EOL;
}
   

    

    receipt += commands.TEXT_FORMAT.TXT_NORMAL;
    receipt += commands.MARGINS.LEFT;
    receipt += "Payment:  "+order.payment_type;
    receipt += commands.EOL;

    receipt += "Order Placed: " + order.order_placed_time;
    receipt += commands.EOL;

    receipt += "Ready By: " + order.scheduled_time;
    receipt += commands.EOL;

    var order_via = 'Online';
    if(order.order_via_display != undefined && order.order_via_display != ''){
      order_via = order.order_via_display;
    }
    else{
      order_via = order.order_via;
    }
    receipt += "Order Via: " + order_via;
    receipt += commands.EOL;

    if (order.instructions.GainDiscount > 0) {
      receipt += "Gain Discount " + "$" + order.instructions.GainDiscount + "%";
      receipt += commands.EOL;
    }
    if (order.instructions.BusinessDiscount > 0) {
      receipt += "Business Discount " + "$" + order.instructions.BusinessDiscount + "%";
      receipt += commands.EOL;
    }
    if (order.instructions.Notes != '' || order.instructions.notes != undefined) {
      receipt += "Notes " + " :" + order.instructions.Notes + ".";
      receipt += commands.EOL;
    }
    if (order.instructions.Points > 0) {
      receipt += "Points Used " + "$" + order.instructions.Points + "%";
      receipt += commands.EOL;
    }
    if (order.instructions.StoreCredit > 0) {
      receipt += "Store Credit " + "$" + order.instructions.StoreCredit + "%";
      receipt += commands.EOL;
    }
    if (order.instructions.Tip > 0) {
      receipt += "Tip(Tip Included) " + "$" + order.instructions.Tip;
      receipt += commands.EOL;
    }
    // receipt += commands.EOL;
    // receipt += commands.MARGINS.LEFT;
    // receipt += commands.TEXT_FORMAT.TXT_BOLD_OFF;
    // receipt += "Total Items";
    // receipt += commands.EOL;

    receipt += commands.TEXT_FORMAT.TXT_4SQUARE;
    receipt += commands.MARGINS.LEFT;
    receipt += commands.EOL;
    receipt += "Total Item(s) "+this.orders.order_data.length;

    receipt += commands.TEXT_FORMAT.TXT_NORMAL;
    receipt += commands.EOL;

    receipt += commands.HORIZONTAL_LINE.HR3_58MM;

    receipt += commands.EOL;
    // receipt += commands.TEXT_FORMAT.TXT_BOLD_ON;
    // receipt += "Item Name" + tab + '' + tab + '' + tab + '' + tab + '' + tab + "Qty" + tab + tab + "Amount"
    // receipt += commands.EOL;

    receipt += commands.TEXT_FORMAT.TXT_NORMAL;
    // receipt += commands.EOL;
    var that = this;


  }
//conditions ends here

    //items  condition starts here 
    let orderdata=order.order_data
    console.log('orders length    ',orderdata.length)
   orderdata.forEach(items => {
      var tabs = '';
      // console.log('foreach error ',order.order_data)
      //orderitems

      //changed on 28-jun
      // receipt += commands.TEXT_FORMAT.TXT_NORMAL;    
      //changed on 28-jun
    // receipt += commands.TEXT_FORMAT.TXT_4SQUARE;
    receipt += commands.TEXT_FORMAT.TXT_4SQUARE
    receipt += commands.TEXT_FORMAT.TXT_FONT_B
      //changed on 28-jun


      // receipt += commands.TEXT_FORMAT.TXT_2WIDTH;
      // receipt += commands.TEXT_FORMAT.TXT_BOLD_ON;
      receipt += commands.TEXT_FORMAT.TXT_ALIGN_LT;

      if (Number(items.totalPrice) > 0) {
        receipt += items.quantity + '-  ' + items.menuItem;
        receipt += commands.EOL;
        receipt += commands.TEXT_FORMAT.TXT_ALIGN_RT
        receipt += '$' + items.totalPrice;
      receipt += commands.EOL;

      }
      else {
        receipt += items.quantity + '   ' + items.menuItem;
      receipt += commands.EOL;

      }

      if (items.itemInstructions!="undefined " && items.itemInstructions!='' && items.itemInstructions!=undefined) {
        receipt += commands.TEXT_FORMAT.TXT_NORMAL;
        receipt += commands.TEXT_FORMAT.TXT_BOLD_OFF;
        receipt += commands.TEXT_FORMAT.TXT_ALIGN_LT;
        receipt += commands.TEXT_FORMAT.TXT_2HEIGHT;
        receipt += "Notes:" + items.itemInstructions
          receipt += commands.EOL;

      }
      // receipt += commands.EOL;
     
//extras
      if (items.menuExtrasSelected.length > 0) {
        console.log('extras  ',items.menuExtrasSelected)
        
        items.menuExtrasSelected.forEach(extra => {
          console.log('extras ')
          receipt += commands.TEXT_FORMAT.TXT_BOLD_OFF;
          receipt += commands.TEXT_FORMAT.TXT_ALIGN_LT;
          // receipt += commands.EOL;

          // if (that.extra_category_title_print) {
          //   receipt += commands.EOL;
          //   receipt +=  extra.heading;
          //   receipt += commands.EOL;

          // }
          extra.optionNameSelected.forEach(option => {
            receipt += commands.TEXT_FORMAT.TXT_BOLD_OFF;
            receipt += commands.TEXT_FORMAT.TXT_ALIGN_LT
           receipt += commands.TEXT_FORMAT.TXT_2HEIGHT;


            if (Number(option.price) > 0) {
              receipt += commands.EOL;
              receipt += commands.TEXT_FORMAT.TXT_ALIGN_LT
              receipt += '  -'+option.name;

              // receipt += option.quantity + '- ' + option.name + '  ($' + option.price + ')';
              // receipt += option.name;
              // receipt += commands.EOL;
              // receipt += commands.TEXT_FORMAT.TXT_ALIGN_RT
              // receipt += option.price;
              // receipt += commands.TEXT_FORMAT.TXT_ALIGN_LT

            }
            else {
              receipt += commands.EOL;
              receipt += commands.TEXT_FORMAT.TXT_ALIGN_LT
              receipt += ' '+option.name;
              // receipt += option.quantity + '- ' + option.name;
              // receipt += option.name;

            }

            // receipt += commands.EOL;
          });
        });
       
        // receipt += commands.EOL;
      }

//items conditions ends here

      ////footer prints at end


      receipt += commands.EOL;
    


      // receipt += commands.EOL;
      receipt += commands.TEXT_FORMAT.TXT_NORMAL;
      receipt += commands.TEXT_FORMAT.TXT_BOLD_OFF;
      receipt += commands.HORIZONTAL_LINE.HR3_58MM;
      receipt += commands.EOL;
    });


    if(inc==limit){    //limit is 8 and i is 8
      console.log('i and limit is equal print footer',inc,limit);


    // receipt += commands.EOL;

    receipt += commands.TEXT_FORMAT.TXT_NORMAL;
    //receipt += commands.HORIZONTAL_LINE.HR_58MM;
    receipt += commands.EOL;

    receipt += commands.TEXT_FORMAT.TXT_NORMAL;
    receipt += commands.TEXT_FORMAT.TXT_CUSTOM_SIZE(1.5,1.5);
    receipt += commands.TEXT_FORMAT.TXT_ALIGN_RT;
    receipt += "SubTotal: " + "$" + parseFloat(this.ProductsTotal).toFixed(2);
    receipt += commands.EOL;
    if (order.instructions.BusinessDiscount > 0) {
      // receipt += "Discount: " + "$" + parseFloat(this.DiscountDisplay).toFixed(2);
      receipt += "Discount: " + "-$" + this.discountTotal;
      receipt += commands.EOL;
    }
    if(order_type=='DELIVERY'){
    receipt += "Delivery Charges: " + "$" + parseFloat(order.delivery_fee).toFixed(2);
    receipt += commands.EOL;
  }

    if (this.giftcard_count > 0) {
      receipt += "Gift Card Used: " + "-$" + parseFloat(this.giftcard_count).toFixed(2);
      receipt += commands.EOL;
    }
    receipt += "Tax: " + "$" + parseFloat(order.instructions.tax);
    receipt += commands.EOL;

    receipt += "Tip: " + "$" + parseFloat(order.instructions.Tip).toFixed(2);
    receipt += commands.EOL;

    if (parseFloat(order.instructions.cash_discount) > 0) {
      receipt += "CC Fee: " + "$" + parseFloat(order.instructions.cash_discount).toFixed(2);
    receipt += commands.EOL;
    }
    // plaform fee
    if (order.instructions.platformFeeEnabled && parseFloat(order.instructions.platformFeeAmount)>0) {
      receipt +=  order.instructions.platformFeeLabel+": " + "$" + parseFloat(order.instructions.platformFeeAmount).toFixed(2);
    }

    receipt += commands.EOL;

    receipt += commands.TEXT_FORMAT.TXT_NORMAL;
    receipt += commands.EOL;
    receipt += commands.HORIZONTAL_LINE.HR_58MM;
    receipt += commands.EOL;

    receipt += commands.TEXT_FORMAT.TXT_4SQUARE;
    receipt += commands.TEXT_FORMAT.TXT_ALIGN_RT;
    receipt += "Total:$" + order.total;
    receipt += commands.EOL;

    receipt += commands.TEXT_FORMAT.TXT_NORMAL;
    receipt += commands.EOL;
    receipt += commands.HORIZONTAL_LINE.HR_58MM;
    receipt += commands.EOL;

    // receipt += commands.TEXT_FORMAT.TXT_NORMAL;
    // // receipt += commands.TEXT_FORMAT.TXT_BOLD_ON;
    // receipt += "Thanks";
    // receipt += commands.EOL;

    


    receipt += commands.EOL;

    //secure space on footer
    receipt += commands.TEXT_FORMAT.TXT_NORMAL;
    receipt += commands.TEXT_FORMAT.TXT_BOLD_OFF
    receipt += commands.TEXT_FORMAT.TXT_ALIGN_CT

    receipt += '___________________________________________';
    receipt += commands.EOL;
    receipt += '___________________________________________';
    receipt += commands.EOL;
    receipt += '___________________________________________';
    receipt += commands.EOL;
    receipt += '___________________________________________';
    receipt += commands.EOL;
    receipt += '___________________________________________';
    receipt += commands.EOL;
    receipt += '___________________________________________';
    receipt += commands.EOL;
    receipt += '___________________________________________';
    receipt += commands.EOL;
    receipt += '___________________________________________';
    receipt += commands.EOL;
    receipt += '___________________________________________';
    receipt += commands.EOL;
    receipt += commands.EOL;
    receipt += commands.EOL;

  }
  //footer nds
// pending
    this.vibration.vibrate(200);
    // this.globals.showToast('Printing orders...','secondary');
    // this.toast=await this.toastCtrl.create({
    //     message: 'Printing...',
  
    //   });
    //   await this.toast.present()
    let p_type=localStorage.getItem('printerType')

      if(p_type!=null && p_type!=undefined){

        this.globals.printerTYPE=p_type

    }

    if (this.globals.printerTYPE == 'regular') {
      this.print(this.globals.address_printer, receipt,order);
    } else {
      this.starPrintOrder(order);
    }
  }
  
  async printMessage ( data,msg,autoPrintMsgCheck){
   
      let that =this
      console.log('address ',this.globals.address_printer,'reciept ',data,'data  ',msg)
      
      that.printService.enableBluetooth().then(success=>{
        console.log('bluetooth enabled ')
      that.printService.connectBluetooth(this.globals.address_printer).subscribe(
        (status) => {
          console.log('connected',status);
          that.printService.printData(this.noSpecialChars(data))
            .then((printStatus) => {
              this.globals.printNextMessage=true;
            
            
              console.log('print success now save order to list of printed ',printStatus);
              if(autoPrintMsgCheck){
                that.messagePrintedSuccess(msg);
              }
            
              that.printService.disconnectBluetooth();
            })
            .catch((error) => {
              console.log(error);
              
              // this.toast.dismiss();
              that.globals.showToast(this.errorPrinting,'danger');
            });
        },
        (error) => {
          console.log('bluetooth error ',error);
          

          // this.toast.dismiss();
          that.globals.showToast(this.errorConnecting,'danger');
        },
      );
    },err=>{
      console.log(err,'cant turn on bluetooth',);
   

      this.ringtone.stopRing();
    })
   
   

  }
  async print(device, data,order) {
    // if(order.order_data.length==0){
      // return;
    // }
    console.log('address ',device,'reciept ',data,'data  ',order)
    
    this.printService.enableBluetooth().then(success=>{
      console.log('bluetooth enabled ')
    this.printService.connectBluetooth(device).subscribe(
      (status) => {
        console.log('connected',status);
        this.printService.printData(this.noSpecialChars(data))
          .then((printStatus) => {
            // this.i++;
            // console.log(printStatus)
            // this.checkSomething=true;
            console.log('print success now save order to list of printed ',printStatus);
            // if(autoPrintMsgCheck){
            //   this.messagePrintedSuccess(order);
            // }else{
            this.orderPrintedSucees(order.order_number)

            
            this.globals.showToast(this.printSuccess,'success');

            // this.toast.dismiss();
            this.globals.check=true;

            // if(this.lastPrinted==true){
            // clearInterval(this.newInterval);

            // }
            this.printService.disconnectBluetooth();
          })
          .catch((error) => {
            console.log(error);
            // this.toast.dismiss();
            this.globals.showToast(this.errorPrinting,'danger');
          });
      },
      (error) => {
        console.log('bluetooth error ',error);
        // this.toast.dismiss();
        this.globals.showToast(this.errorConnecting,'danger');
      },
    );
  },err=>{
    console.log(err,'cant turn on bluetooth',);
    // this.bluetooth.isEnabled().then(res=>{
    //   console.log('bluetooth',res)
      
    // })

    this.ringtone.stopRing();
// if(autoPrintMsgCheck){
//   this.getPermissionsBluetooth(device, data,order, autoPrintMsgCheck)
// }


  })
  }
  // getPermissionsBluetooth(device, data,order, autoPrintMsgCheck?){
  //   if(this.platform.is('android')){
     
    
  //     this.androidP.checkPermission(this.androidP.PERMISSION.BLUETOOTH_CONNECT).then(res=>{
  //       console.log('checking permission bluetooth',res)
  //       if(res.hasPermission){
  //         console.log('has bluetooth')
  //         return true
  //       }
  //       else{
  //         this.androidP.requestPermission(this.androidP.PERMISSION.BLUETOOTH_CONNECT).then(res=>{
  //           console.log('granted else ,',res)
  //               // this.getPrinters()
  //               if(autoPrintMsgCheck){

  //               this.print(device, data,order, autoPrintMsgCheck)

  //               }

  //           // this.androidP.checkPermission(this.androidP.PERMISSION.BLUETOOTH).then(res=>{
  //           //   console.log('bluetooth',res)
  //           // },err=>{
  //           //   this.androidP.requestPermission(this.androidP.PERMISSION.BLUETOOTH_CONNECT).then(res=>{
  //           //     this.getPrinters()
  //           //   })
  //           // })
  //         // return true
  
  //         })
  //       }
  //     },err=>{
  //       this.androidP.requestPermission(this.androidP.PERMISSION.BLUETOOTH_CONNECT).then(res=>{
  //         console.log('granted in error,',res);
  //         // this.getOrder();
  //         // this.print(device, data,order, autoPrintMsgCheck?);
  //         if(autoPrintMsgCheck){

  //           this.print(device, data,order, autoPrintMsgCheck)

  //           }
  //         // return true
  
  //       })
  //     })
  //   }

  // }

  messagePrintedSuccess(msg){
    this.globals.printedMessage.push(msg.id);
    localStorage.setItem("printedMessageArray", JSON.stringify(this.globals.printedMessage));
  }

  noSpecialChars(string) {
    var translate = {
      à: 'a',
      á: 'a',
      â: 'a',
      ã: 'a',
      ä: 'a',
      å: 'a',
      æ: 'a',
      ç: 'c',
      è: 'e',
      é: 'e',
      ê: 'e',
      ë: 'e',
      ì: 'i',
      í: 'i',
      î: 'i',
      ï: 'i',
      ð: 'd',
      ñ: 'n',
      ò: 'o',
      ó: 'o',
      ô: 'o',
      õ: 'o',
      ö: 'o',
      ø: 'o',
      ù: 'u',
      ú: 'u',
      û: 'u',
      ü: 'u',
      ý: 'y',
      þ: 'b',
      ÿ: 'y',
      ŕ: 'r',
      À: 'A',
      Á: 'A',
      Â: 'A',
      Ã: 'A',
      Ä: 'A',
      Å: 'A',
      Æ: 'A',
      Ç: 'C',
      È: 'E',
      É: 'E',
      Ê: 'E',
      Ë: 'E',
      Ì: 'I',
      Í: 'I',
      Î: 'I',
      Ï: 'I',
      Ð: 'D',
      Ñ: 'N',
      Ò: 'O',
      Ó: 'O',
      Ô: 'O',
      Õ: 'O',
      Ö: 'O',
      Ø: 'O',
      Ù: 'U',
      Ú: 'U',
      Û: 'U',
      Ü: 'U',
      Ý: 'Y',
      Þ: 'B',
      Ÿ: 'Y',
      Ŕ: 'R',
    },
      translate_re = /[àáâãäåæçèéêëìíîïðñòóôõöøùúûüýþßàáâãäåæçèéêëìíîïðñòóôõöøùúûýýþÿŕŕÀÁÂÃÄÅÆÇÈÉÊËÌÍÎÏÐÑÒÓÔÕÖØÙÚÛÜÝÞßÀÁÂÃÄÅÆÇÈÉÊËÌÍÎÏÐÑÒÓÔÕÖØÙÚÛÝÝÞŸŔŔ]/gim;
    return string.replace(translate_re, function (match) {
      return translate[match];
    });
  }
  starPrintOrder(order) {
    var items_string = "";
    var extraSpaces = '';
    var spaces = '';
    var amount_spaces = '';
    var amount: any;
    var subtotal = 0;
    var that = this;
    order.order_data.forEach(function (order_info) {
      spaces = '';
      amount_spaces = '';
      if (order_info.menuItem.length < 19) {
        for (let i = 0; i < 18 - order_info.menuItem.length; i++) {
          spaces += ' ';
        }
      } else {
        spaces = '\n                  ';
      }

      for (let i = 0; i <= (6 - order_info.basePrice.toString().length); i++) {
        amount_spaces += ' ';
      }
      // items_string += order_info.menuItem + spaces + order_info.quantity + '     ' + parseFloat(order_info.basePrice).toFixed(2) + amount_spaces + parseFloat(order_info.totalPrice).toFixed(2) + '\n';
      items_string += 'x' + order_info.quantity + ' (' + parseFloat(order_info.totalPrice).toFixed(2) + ')  \n' + order_info.menuItem + '\n';
      
      if (order_info.menuExtrasSelected.length > 0) {
        for (var i = 0; i <= (18 - order_info.menuExtrasSelected.length); i++) {
          extraSpaces += ' ';
        }
        order_info.menuExtrasSelected.forEach(extra => {
          if (that.extra_category_title_print) {
            items_string += '(' + extra.heading + ')\n';
          }
          extra.optionNameSelected.forEach(option => {
            items_string += '> ' + option.name + extraSpaces + option.price + '\n';
          });
        });
      }
      if (order_info.itemInstructions != '') {
        items_string += 'Notes: ' + order_info.itemInstructions + '\n'
      }
      if(order.order_data[order.order_data.length - 1] != order_info){
        // console.log('item not equal to last item')
        items_string += that.line4;
      }
      else{
        // console.log('item equal to last item')
      }


    });

    if (order.instructions.BusinessDiscount > 0) {
      this.businessDiscount = order.instructions.BusinessDiscount;
    }
    if (order.instructions.Notes != '') {
      this.notes = order.instructions.Notes;
    }
    if (order.instructions.Points > 0) {
      this.pointUsed = order.instructions.Points;
    }
    // if (order.instructions.StoreCredit > 0) {
    //   this.storeCredit = "Store Credit " + "$" + order.instructions.StoreCredit + "% \n"
    // }
    if (order.instructions.Tip > 0) {
      this.tipInclud = order.instructions.Tip;
    }

    var order_via = 'Online';
    if(order.order_via_display != undefined && order.order_via_display != ''){
      order_via = order.order_via_display;
    }
    else{
      order_via = order.order_via;
    }

    // console.log("Take print Func Start");
    var rasterObj = {
      text: this.globals.name.toUpperCase() + "\n" +

        "Customer Details \n" +
        this.line1 +
        "Customer Name: " + order.customer_name + "\n" +
        "Customer ID: " + order.customer_id + "\n" +
        "Phone: " + order.customer_phone + "\n\n" +

        "Order Details \n" +
        "Order ID:" + order.order_number + "\n" +
        "Address: " + order.address + "\n" +
        "Scheduled Time: " + order.scheduled_time + "\n" +
        "Order Placed Time: " + order.order_placed_time + "\n" +
        "Order via: " + order_via + "\n" +
        "Payment Method: " + order.payment_type + "\n\n" +

        "Instructions -" + "\n" +
        // "Gain Discount " + "$" + order.instructions.GainDiscount + "%" + "\n" +
        "Business Discount: " + this.businessDiscount + "%" + "\n" +
        "Notes: " + this.notes + "\n" +
        "Points Used: " + this.pointUsed + "\n" +
        // "Store Credit " + "$" + order.instructions.StoreCredit + "%" + "\n" +
        "Tip(Tip Included): " + "$" + Number(order.instructions.Tip).toFixed(2) + "\n" +
        "CC Fee: " + "$" + Number(order.instructions.cash_discount).toFixed(2) + "\n" +

        "Type: " + order.instructions.Type + "\n\n\n" +

        this.line3 +
        // "Item Name         Qty   Rate   Amount\n" +
        // this.line3 +
        items_string +
        this.line3 +
        "             SubTotal: $" + parseFloat(this.ProductsTotal).toFixed(2) + "\n" +
        "                  Tax: $" + parseFloat(order.instructions.tax) + "\n" +
        "                  Tip: $" + parseFloat(order.instructions.Tip).toFixed(2) + "\n" +
        this.line2 +
        "                Total: $" + order.total + "\n" +
        this.line2 +
        "___________________________\n"
      ,

      fontSize: 30,       //Defaults to 25
      paperWidth: 576,    // options: 384 = 2", 576 = 3", 832 = 4"
      cutReceipt: true, // Defaults to true
      openCashDrawer: true // Defaults to true
    };

    // this.starprnt.connect('BT:' + this.address_printer, 'StarGraphic', void function (err, res) {
    //   console.log(res);

    try {
      if (this.globals.address_printer != undefined && this.globals.address_printer != null) {
        this.starprnt.printRasterReceipt('BT:' + this.globals.address_printer, 'StarGraphic', rasterObj).then((res: any) => {
          // this.starprnt.disconnect();
          // this.disableButton = false;
        }).catch((error: any) => {
          console.error(error, "not printed");
          this.printerFailure(order);
        });
        this.starprnt.disconnect();
      } else {
        this.connectionFailure();
      }
    }
    catch (Error) {
      this.connectionFailure();
      // this.disableButton = false;
    }
    // console.log("Take print Func end");
    // this.setArray();
    // });
  }

  async printerFailure(order) {
    let alert = await this.alertCtrl.create({
      message: 'Printing Failed. Do you want to retry Print.',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          handler: () => {
            // console.log('Cancel clicked');
          }
        },
        {
          text: 'Re-Try',
          role: 'Re-Try',
          handler: () => {
            this.starPrintOrder(order);
            // console.log('Re-Try');
          }
        }
      ]
    });
    alert.present();
  }
  Total(order) {
  //  console.log('items in order', order.order_data)
    this.total = 0;
    for (let sub of this.orders.order_data) { //new added

      this.total = Number(this.total);
      sub.totalPrice = Number(sub.totalPrice);

      this.total += Number(sub.totalPrice);
      this.ProductsTotal = this.total;
    }
    if (this.orders.instructions.BusinessDiscount > 0) {
      this.discountTotal = Number(this.ProductsTotal) * Number(this.orders.instructions.BusinessDiscount) / 100;
      this.discountTotal = Math.round(this.discountTotal * 100) / 100;
      // this.discountTotal.toFixed(2);
      this.total = Number(this.ProductsTotal) - Number(this.discountTotal);

      // this.Total = this.Total.toFixed(2);
      //this.globals.BusinessDiscountFlag = true;
    }
    if (this.orders.instructions.Type == "Delivery" || this.orders.instructions.Type == "delivery") {
      this.total = Number(this.orders.delivery_fee) + this.total;
    }

    this.TaxCalculate(this.orders);
  }

  TaxCalculate(order) {
    var taxcalc = (Number(order.tax) / 100) * Number(this.total);
    this.tax_calc = taxcalc.toFixed(2);
    this.total = this.total + taxcalc;

   let total = Number(this.total.toFixed(2));
   this.total=Number(total)

  }
  async connectionFailure() {
    let alert = await this.alertCtrl.create({
      message: 'Printing connectionFailure. Select printer.',
      buttons: [
        {
          text: 'ok',
          role: 'ok',
          handler: () => {
            // console.log('ok clicked');
          }
        },
      ]
    });
    alert.present();
  }
  orderPrintedSucees(order_number) {
    if(this.globals.manualPrintOrder==true){ //checking if printing comingfrom order page. if comingfrom order page don't save in auto printing array
        this.globals.manualPrintOrder=false;
        //not saving order. and set manual print to false.  
    }
    else{
    this.globals.ordersPrinted.push(order_number);
    // console.log('printed list ',this.globals.ordersPrinted)
    localStorage.setItem("ordersPrinted", JSON.stringify(this.globals.ordersPrinted));
    }
  }
}
