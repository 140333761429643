import { HttpClient, HttpClientModule } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class StaffService {
  private baseUrl: string = environment.apiEndPoint;
  user:any;

  constructor(private http:HttpClient) { }

  loginStaff(data:any){
    let url= this.baseUrl+'Business_controller/app_login'
    return this.http.post(url,data)
  }
}
