import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GlobalUserServiceService {
    userDataSubject=new BehaviorSubject<any>(null);
    pageTitleSubject=new BehaviorSubject<any>('Menu');
    userData=this.userDataSubject.asObservable();
    pageTitleData=this.userDataSubject.asObservable();
    GlobalModelSubject=new BehaviorSubject<any>(null);
    globalModelData=this.userDataSubject.asObservable();

    deleteModelSubject=new BehaviorSubject<any>(false);
   
    deleteModelData=this.userDataSubject.asObservable();
    isEdit=false;


  constructor() { }

  updateUser(user:any){
    this.userDataSubject.next(user);
  }
  updatePageTitle(user:any){
    this.pageTitleSubject.next(user);
  }
  updateglobalModel(user:any,){
    this.GlobalModelSubject.next(user);
  }
 

  updateDeleteModel(user:any){
    this.deleteModelSubject.next(user);
  }

}
