import { Component, OnInit } from '@angular/core';
import { AlertController, ModalController } from '@ionic/angular';
import { ServerService } from '../providers/server.service';
import { Camera,CameraOptions } from '@ionic-native/camera/ngx';
import { HttpClient } from '@angular/common/http';
import { GlobalVariable } from '../global';
import { NavController } from '@ionic/angular';
import { Router } from '@angular/router';
import { StaffService } from '../services/staff.service';
import * as moment from 'moment';
@Component({
  selector: 'app-photomodal',
  templateUrl: './photomodal.page.html',
  styleUrls: ['./photomodal.page.scss'],
})
  export class PhotomodalPage implements OnInit {
base64Image:string;
first_name:any;
userData:any;
  logo:any;
  staffName:any;
  nick_name:any;
  street:any;
  city:any;
  state:any;
  zip:any;
  phone:any;
  alternate_phone:any;
  emergency_contact_phone:number;
  emergency_contact_name: any;
  email:any;
  dob:any;
  lic_ex1:any;
  lic_ex2:any;
  lic_ex3:any;
  citizen:any;
  staffImage:any;
  qrData:any;
  myInputValue: string = ''; // This is where the input value will be stored
  selectedOption:string;
  description:any;
  desc_id:any;
  desc:any;
  lastname:any;
  password:string;
  descriptions: any[] = [];
  file1:any;
  file2:string;
  file3:string;
  file4:string;
  file5:string;
  driving_license:string;
  social_security_card:string;
  business_license1:string;
  business_license2:string;
  business_license3:string;
  constructor(private camera: Camera, private server:ServerService,private modalCtrl: ModalController,private staffservice:StaffService,
    private http:HttpClient, private globals:GlobalVariable, private nav : NavController,private router: Router,public alertCtrl: AlertController
  ) {this.userData=JSON.parse(localStorage.getItem('staffUserData'));
    this.file1=this.userData?.profile_1;
    console.log(this.file1);
    this.first_name=this.userData?.first_name;
    this.lastname=this.userData?.last_name;
    this.nick_name=this.userData?.nickname;
    this.street=this.userData?.street;
    this.city=this.userData?.city;
    this.state=this.userData?.state;
    this.zip=this.userData?.zip;
    this.phone=this.userData?.phone;
    this.alternate_phone=this.userData?.alternate_phone;
    this.email=this.userData?.email;
    this.emergency_contact_name=this.userData?.emergency_contact_name;
    this.emergency_contact_phone=this.userData?.emergency_contact_phone;
    this.dob=this.userData?.dob;
    this.citizen=this.userData?.citizen;
    this.lastname=this.userData?.last_name;
    this.password=this.userData?.staff_password;
    this.file1=this.userData.profile_1;
    this.file1=this.userData?.profile_1;
      this.file2=this.userData?.profile_2;
      this.file3=this.userData?.profile_3;
      this.file4=this.userData?.profile_4;
      this.file5=this.userData?.profile_5;
      this.driving_license=this.userData?.driving_license;
      this.social_security_card=this.userData?.social_security_card;
      this.business_license1=this.userData?.business_license1;
      this.business_license2=this.userData?.business_license2;
      this.business_license3=this.userData?.business_license3;
      console.log(this.file1);
      console.log(this.driving_license);
      console.log(this.business_license1);
  }
  updateTextarea(){
    console.log(this.desc);
    this.desc_id=this.desc.id;
    console.log(this.desc_id,'option');
    this.description=this.desc.description;
    console.log(this.description);
    // console.log(this.descriptions[this.desc_id]);
    // console.log(this.descriptions);
    
  }
 
  async showAlert() {
    const alert = await this.alertCtrl.create({
      header: 'Alert!',
      message: 'Profile Updated Successfully',
      buttons: ['OK']
    });

    await alert.present();
  }
 upload(){
  this.globals.globalname=this.first_name;
  console.log(this.globals.globalname)
  this.globals.last_name=this.lastname;
  this.globals.nick_name=this.nick_name;
  console.log(this.globals.nick_name);
  this.globals.street=this.street;
  console.log(this.globals.street);
  this.globals.city=this.city;
  console.log(this.globals.city);
  this.globals.state=this.state;
  console.log(this.globals.state);
  this.globals.zip=this.zip;
  console.log(this.globals.zip);
  this.globals.phone=this.phone;
  console.log(this.globals.phone);
  this.globals.alternate_phone=this.alternate_phone;
  console.log(this.globals.alternate_phone)
  this.globals.emergency_contact_name=this.emergency_contact_name;
  console.log(this.globals.emergency_contact_name)
  this.globals.emergency_contact_phone=this.emergency_contact_phone;
  console.log(this.globals.emergency_contact_phone)
  this.globals.email=this.email;
  console.log(this.globals.email)
  this.globals.citizen=this.citizen;
  console.log(this.globals.citizen);
  this.globals.description=this.description;
  console.log(this.globals.description)
  this.globals.desc_id=this.desc_id;
  console.log(this.globals.desc_id);
  this.globals.last_name=this.lastname;
  console.log(this.globals.last_name);
  this.globals.password=this.password;
  console.log('password',this.globals.password);
  if(this.dob){
    this.dob = moment(this.dob).format('YYYY-MM-DD');
  }
  console.log(this.dob);
  this.globals.dob=this.dob;
  if(this.lic_ex1){
    this.lic_ex1 = moment(this.lic_ex1).format('YYYY-MM-DD');
  }
  this.globals.business_license1_expiration=this.lic_ex1;
  if(this.lic_ex2){
    this.lic_ex2 = moment(this.lic_ex2).format('YYYY-MM-DD');
  }
  this.globals.business_license2_expiration=this.lic_ex2;
  if(this.lic_ex3){
    this.lic_ex3 = moment(this.lic_ex3).format('YYYY-MM-DD');
  }
this.server.updatestaff().subscribe(res=>{console.log(res)
  localStorage.clear();
  console.log('2');
    this.nav.navigateRoot('/landingpage');
  this.showAlert();
});
 }
  ngOnInit() {
    console.log("userData",this.userData);
    this.staffName=this.userData?.staff_name;
    this.staffImage=this.userData?.image;
   this.logo=this.userData.logo;
    this.qrData = this.userData.login_qr;
    console.log(this.userData.profile_1);
    this.server.getStaffList().subscribe(res=>{
      this.descriptions=res.descriptions;
      console.log(this.descriptions);
    });
    this.userData=JSON.parse(localStorage.getItem('staffUserData'));
    this.file1=this.userData?.profile_1;
    console.log(this.file1);
    this.first_name=this.userData?.first_name;
    this.lastname=this.userData?.last_name;
    this.nick_name=this.userData?.nickname;
    this.street=this.userData?.street;
    this.city=this.userData?.city;
    this.state=this.userData?.state;
    this.zip=this.userData?.zip;
    this.phone=this.userData?.phone;
    this.alternate_phone=this.userData?.alternate_phone;
    this.email=this.userData?.email;
    this.emergency_contact_name=this.userData?.emergency_contact_name;
    this.emergency_contact_phone=this.userData?.emergency_contact_phone;
    this.dob=this.userData?.dob;
    this.citizen=this.userData?.citizen;
    this.lastname=this.userData?.last_name;
    this.password=this.userData?.staff_password;
    this.file1=this.userData.profile_1;
    this.file1=this.userData?.profile_1;
      this.file2=this.userData?.profile_2;
      this.file3=this.userData?.profile_3;
      this.file4=this.userData?.profile_4;
      this.file5=this.userData?.profile_5;
      this.driving_license=this.userData?.driving_license;
      this.social_security_card=this.userData?.social_security_card;
      this.business_license1=this.userData?.business_license1;
      this.business_license2=this.userData?.business_license2;
      this.business_license3=this.userData?.business_license3;
      console.log(this.file1);
      console.log(this.driving_license);
      console.log(this.business_license1);
      
  }
  openFileInput(number:number) {
    const fileInput = document.createElement('input');
    fileInput.type = 'file';
    fileInput.accept = 'image/*';
    fileInput.style.display = 'none';
    fileInput.addEventListener('change', (event: any) => {
        this.onFileChange(event, number);
    });
    document.body.appendChild(fileInput);
    fileInput.click();
}
  onFileChange(event:any,number:number) {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        if (!file.type.startsWith('image/')) {
          console.error('File is not an image.');
          return;
      }
      else{
        if(number == 1){
        this.file1 = e.target.result;
        console.log('Prophile photo',number,' base64:', this.file1);
        if (this.file1) {
          this.server.uploadImage(this.file1).subscribe(res => {
            this.globals.file1=res.image_name;
            console.log('Upload success',this.globals.file1);
            
          }, error => {
            console.error('Upload failed', error);
          });
        } else {
          console.error('No image to upload');
        }
        }
        if(number == 2){
          this.file2 = e.target.result;
          console.log('Prophile photo 2 base64:', this.file2);
          if (this.file2) {
            this.server.uploadImage(this.file2).subscribe(res => {
              this.globals.file2=res.image_name;
              console.log('Upload success',this.globals.file2);
              
            }, error => {
              console.error('Upload failed', error);
            });
          } else {
            console.error('No image to upload');
          }
          }
          if(number == 3){
            this.file3 = e.target.result;
            console.log('Prophile photo 3 base64:', this.file3);
            if (this.file3) {
              this.server.uploadImage(this.file3).subscribe(res => {
                this.globals.file3=res.image_name;
                console.log('Upload success',this.globals.file3);
                
              }, error => {
                console.error('Upload failed', error);
              });
            } else {
              console.error('No image to upload');
            }
            }
            if(number == 4){
              this.file4 = e.target.result;
              console.log('Prophile photo 4 base64:', this.file4);
              if (this.file4) {
                this.server.uploadImage(this.file4).subscribe(res => {
                  this.globals.file4=res.image_name;
                  console.log('Upload success',this.globals.file4);
                  
                }, error => {
                  console.error('Upload failed', error);
                });
              } else {
                console.error('No image to upload');
              }
              }
              if(number == 5){
                this.file5 = e.target.result;
                console.log('Prophile photo 5 base64:', this.file5);
                if (this.file5) {
                  this.server.uploadImage(this.file5).subscribe(res => {
                    this.globals.file5=res.image_name;
                    console.log('Upload success',this.globals.file5);
                    
                  }, error => {
                    console.error('Upload failed', error);
                  });
                } else {
                  console.error('No image to upload');
                }
                }
                if(number == 6){
                  this.driving_license = e.target.result;
                  console.log('driving license base64:', this.driving_license);
                  if (this.driving_license) {
                    this.server.uploadImage(this.driving_license).subscribe(res => {
                      this.globals.driving_license=res.image_name;
                      console.log('Upload success',this.globals.driving_license);
                      
                    }, error => {
                      console.error('Upload failed', error);
                    });
                  } else {
                    console.error('No image to upload');
                  }
                  }
                  if(number == 7){
                    this.social_security_card = e.target.result;
                    console.log('social security card base64:', this.social_security_card);
                    if (this.social_security_card) {
                      this.server.uploadImage(this.social_security_card).subscribe(res => {
                        this.globals.social_security_card=res.image_name;
                        console.log('Upload success',this.globals.social_security_card);
                        
                      }, error => {
                        console.error('Upload failed', error);
                      });
                    } else {
                      console.error('No image to upload');
                    }
                    }
                    if(number == 11){
                      this.business_license1 = e.target.result;
                      console.log('Business license 1 base64:', this.business_license1);
                      if (this.business_license1) {
                        this.server.uploadImage(this.business_license1).subscribe(res => {
                          this.globals.business_license1=res.image_url;
                          console.log('Upload success',this.business_license1);
                          
                        }, error => {
                          console.error('Upload failed', error);
                        });
                      } else {
                        console.error('No image to upload');
                      }
                      }
                      if(number == 12){
                        this.business_license2 = e.target.result;
                        console.log('Business license 2 base64:', this.business_license2);
                        if (this.business_license2) {
                          this.server.uploadImage(this.business_license2).subscribe(res => {
                            this.globals.business_license2=res.image_url;
                            console.log('Upload success',this.business_license2);
                            
                          }, error => {
                            console.error('Upload failed', error);
                          });
                        } else {
                          console.error('No image to upload');
                        }
                        }
                        if(number == 13){
                          this.business_license3 = e.target.result;
                          console.log('Business license 3 base64:', this.business_license3);
                          if (this.business_license3) {
                            this.server.uploadImage(this.business_license3).subscribe(res => {
                              this.globals.business_license3=res.image_url;
                              console.log('Upload success',this.business_license3);
                              
                            }, error => {
                              console.error('Upload failed', error);
                            });
                          } else {
                            console.error('No image to upload');
                          }
                          }
                        }
      };
      reader.readAsDataURL(file);
    }

  }
  
  closeModal(){
    this.modalCtrl.dismiss(); 
  }
  takePicture() {
    const options: CameraOptions = {
      quality: 100,
      destinationType: this.camera.DestinationType.FILE_URI,
      encodingType: this.camera.EncodingType.JPEG,
      mediaType: this.camera.MediaType.PICTURE,
      sourceType: this.camera.PictureSourceType.PHOTOLIBRARY // Set sourceType to PHOTOLIBRARY to open the gallery
    };
    
    this.camera.getPicture(options).then((imageData) => {
      this.base64Image = 'data:image/jpeg;base64,' + imageData;
      this.uploadImage();
    }, (err) => {
      console.log('Camera issue:' + err);
    });
  }

  uploadImage() {
    if (this.base64Image) {
      this.server.uploadImage(this.base64Image).subscribe(res => {
        console.log('Upload success', res,res.image_name);
        this.globals.image=res.image_name;
        this.server.updatestaff().subscribe(res=>{
          console.log('Upload success', res);
        this.closeModal();}
        );
      }, error => {
        console.error('Upload failed', error);
      });
    } else {
      console.error('No image to upload');
    }
  }
  go_back(){
    this.globals.file1='';
      this.globals.file2='';
      this.globals.file3='';
      this.globals.file4='';
      this.globals.file5='';
      this.globals.driving_license='';
      this.globals.social_security_card='';
      this.globals.business_license1='';
      this.globals.business_license2='';
      this.globals.business_license3='';
    this.router.navigate(['/staff/staff-timekeeper']);
  }
}
