import { Component, Input, OnInit } from '@angular/core';
import { GlobalVariable } from 'src/app/global';
import { ToastController } from '@ionic/angular';
import { ServerService } from 'src/app/providers/server.service';

@Component({
  selector: 'app-order-card',
  templateUrl: './order-card.component.html',
  styleUrls: ['./order-card.component.scss'],
})
export class OrderCardComponent implements OnInit {
  @Input('orderType') orderType;
  @Input('scheduled_time') scheduled_time;
  @Input('customer_name') customer_name;
  @Input('customer_phone') customer_phone;
  @Input('order_number') order_number;
  @Input('status') status;
  @Input('total') total;
  @Input('payment_type')payment_type;
  @Input('modified')modified;
  today_date: any;
  sch_date:any;
  today: boolean=false;
  time: any;

  // @Input('orderId') orderId;
  constructor(public globals:GlobalVariable, public toastController: ToastController, public server: ServerService) { }

  ngOnInit() {
    // console.log("Order Id is:", this.orderId)
    console.log("Status is:", this.status)
    let date=new Date(this.scheduled_time).toLocaleDateString('en-US')
    let time= new Date(this.scheduled_time).toLocaleTimeString('en-US');
    this.time=time;
    console.log('date sch',date,time)
    let sch_date = date;
    this.sch_date=sch_date;
    // console.log('sch date',this.sch_date);


    let newdate= new Date().toLocaleDateString('en-US');
    console.log('todays date',newdate);
    if(sch_date==newdate){
      this.today=true;
    }
    console.log('mmmmm',this.modified)




  }

  
}
