import { Component, OnInit } from '@angular/core';
import {
  AlertController,
  ModalController,
  NavController,
  Platform,
  ToastController,
} from '@ionic/angular';
import { GlobalVariable } from './global';
import { ServerService } from './providers/server.service';
import { StatusBar } from '@ionic-native/status-bar/ngx';
// import { OneSignal } from '@ionic-native/onesignal/ngx';
import { NativeStorage } from '@ionic-native/native-storage/ngx';
import { commands } from './printer-commands';
import { PrinterServiceService } from './providers/printer-service.service';
// import { OneSignal, OSNotificationPayload } from '@ionic-native/onesignal/ngx';
import { NativeAudio } from '@ionic-native/native-audio/ngx';
import { PrintNowService } from './providers/print-now.service';
import { RingtoneService } from './providers/ringtone/ringtone.service';
import { BackgroundMode } from '@ionic-native/background-mode/ngx';
// import { LottieSplashScreen } from '@ionic-native/lottie-splash-screen/ngx';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { AndroidPermissions } from '@awesome-cordova-plugins/android-permissions/ngx';
import {
  InAppBrowserOptions,
  InAppBrowser,
} from '@ionic-native/in-app-browser/ngx';
import * as moment from 'moment';
import { DateSelectionPage } from './date-selection/date-selection.page';
import { StockOutPage } from './stock-out/stock-out.page';
import { GlobalUserServiceService } from './services/global-user-service.service';
// import { Insomnia } from '@ionic-native/insomnia/ngx'; //for keep app awake . screen will be on till app is in forground. and active
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  ready_menu: any;
  appId: any = '77c7af42-a792-447b-bd4e-4d2f00346462';
  // appId = '928bda14-3c5e-4376-b1a3-942550a8176c';
  googleProjectId: any = '773485528357';
  // googleProjectId= '270603337961';
  ProductsTotal: number;
  CartTax: number;
  pos_items_discount: number = 0;
  Orderdata: any;
  Total: number;
  showOtherOrders = false;
  DiscountDisplay: string;
  CartTaxDisplay: string;
  TotalDisplay: string;
  refreshIntervalId: any;
  check: boolean = false;
  date: string;
  pendingOrders = [];
  printingQueue: any[];
  ringtoneIntervalId: any;
  response: any;
  flag: boolean = false;
  messageArray: any = [];
  branches: any;

  // In App Browser Code starts from here
  options: InAppBrowserOptions = {
    location: 'yes', //Or 'no'
    hidden: 'no', //Or  'yes'
    clearcache: 'yes',
    clearsessioncache: 'yes',
    zoom: 'yes', //Android only ,shows browser zoom controls
    hardwareback: 'yes',
    mediaPlaybackRequiresUserAction: 'no',
    shouldPauseOnSuspend: 'no', //Android only
    // closebuttoncaption : 'Close', //iOS only
    disallowoverscroll: 'no', //iOS only
    toolbar: 'yes', //iOS only
    enableViewportScale: 'no', //iOS only
    allowInlineMediaPlayback: 'no', //iOS only
    presentationstyle: 'pagesheet', //iOS only
    fullscreen: 'yes', //Windows only
  };
  showToastOnce: boolean = false;
  name: any;
  showGlobalModel: any;
  currentRoute: any;

  constructor(
    public globals: GlobalVariable,
    private platform: Platform,
    private server: ServerService,
    private nav: NavController,
    private toastController: ToastController,
    private statusbar: StatusBar,
    private androidP: AndroidPermissions,
    // private oneSignal: OneSignal,
    private nativeStorage: NativeStorage,
    private printer: PrinterServiceService,
    private nativeAudio: NativeAudio,
    private ServicePrint: PrintNowService,
    private ringtone: RingtoneService,
    private backgroundMode: BackgroundMode,
    private router: Router,
    private theInAppBrowser: InAppBrowser,
    private alertCtrl: AlertController,
    private userDataService: GlobalUserServiceService,
    private modalCtrl: ModalController // private lottieSplash:LottieSplashScreen,
  ) {
    // localStorage.clear();
    // console.log('local storage cleared');
    router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.currentRoute = event.url;
      }
    });
    this.userDataService.GlobalModelSubject.subscribe((data) => {
      this.showGlobalModel = data?.state;
    });
    const now = new Date();
    var month = Number(now.getMonth()) + 1;
    this.date = '' + now.getDate() + '/' + month + '/' + now.getFullYear() + '';
    // ready platform
    this.platform.ready().then(() => {
      // this.lottieSplash.show();
      //   <preference name="FadeSplashScreen" value="false"/>
      //   <preference name="AutoHideSplashScreen" value="false"/>
      //  <preference name="LottieAnimationLocation" value="www/assets/splash.json" />
      //  <preference name="LottieFullScreen" value="true" />
      //  <preference name="LottieLoopAnimation" value="true" />
      this.statusbar.overlaysWebView(false);
      if (window.statusbar) {
        this.statusbar.show();
      }
      //12:35/ 12-5-2021
      this.statusbar.backgroundColorByHexString('#0071e6');
      this.statusbar.styleLightContent();
      // setTimeout(() => {
      //   this.lottieSplash.hide()
      //   }, 3000);
      // this.statusbar.styleDefault();
      // this.initializePushToken()
      // this.backgroundMode.isScreenOff(res=>{
      this.checkBluetooth(); // new 10jan
      // });
      this.loadsounds();
      let isLogin = localStorage.getItem('isLogin');
      let localData=JSON.parse(localStorage.getItem('staffUserData'));
      //checking for new orders every 15sec.
      this.refreshIntervalId = setInterval(() => {
        this.globals.user_type = localStorage.getItem('user_type');
        this.globals.busniess_id = localData.id;
        this.globals.name = localStorage.getItem('name');
        this.globals.chatEnable = localStorage.getItem('chatEnable');
        this.globals.phoneNumber = localStorage.getItem('phoneNumber');
        if (
          localStorage.getItem('ordersPrinted') &&
          localStorage.getItem('ordersPrinted') != 'undefined'
        ) {
          this.globals.ordersPrinted = JSON.parse(
            localStorage.getItem('ordersPrinted')
          );
        }
        let continuesring = localStorage.getItem('continuering');
        if (continuesring != null) {
          this.globals.continuousRing = continuesring;
        }
        isLogin = localStorage.getItem('isLogin');
        let autoPrint = localStorage.getItem('autoprint');
        if (autoPrint != null && autoPrint != undefined) {
          this.globals.autoPrint = autoPrint;
        }
        if (isLogin == 'true') {
          // if (this.globals.autoPrint == 'true') {
          // if(this.flag == true){

          this.getOrders();
          // this.flag=false;
          // }
          // }
        } else {
        }
        // if(this.flag == true){

        // this.flag = false;
        // }
      }, 10000);
      this.ringtoneIntervalId = setInterval(() => {
        // this.flag = true;

        if (this.platform.is('cordova')) {
          // if (this.flag) {
          // if (this.globals.autoPrint == 'true') {
          // if(this.flag == true){

          this.playRingtone(this.response);
          this.flag = false;
        }
      }, 5000);

      if (isLogin == 'true') {
        this.go_to_ready_menu(true, undefined);

        this.AdvancedSettings();

        if (this.globals.ASPendingOrders) {
          if (localStorage.getItem('usePaymentsOnly') === 'true') {
            console.log('state is', localStorage.getItem('usePaymentsOnly'));
            this.nav.navigateRoot('/payments-only');
          } else {
            this.stockout_items();
            this.nav.navigateRoot('/home');
          }
        } else {
          this.nav.navigateRoot('/settings');
        }
      } else {
        // this.nav.navigateRoot('/login');
        console.log('4');
        if(localStorage.getItem('isStaffLogin')==='true')
        {
          this.router.navigate(['/staff/staff-timekeeper']);
        }
        else{
        this.nav.navigateRoot('/landingpage');
      }
      }
      //  onesignal.............................
      // console.log(this.appId, this.googleProjectId)
      if (this.platform.is('cordova')) {
        // console.log("cordova")
        // if (this.platform.is('ios')) {
        //   // console.log('ios')
        //   var iosSettings = {};
        //   iosSettings["kOSSettingsKeyAutoPrompt"] = true;
        //   iosSettings["kOSSettingsKeyInAppLaunchURL"] = false;
        //   this.oneSignal.startInit(this.appId).iOSSettings(iosSettings);
        // } else if (this.platform.is('android')) {
        //   // console.log('android')
        //   this.oneSignal.startInit(this.appId, this.googleProjectId);
        // }
        // this.oneSignal.inFocusDisplaying(this.oneSignal.OSInFocusDisplayOption.Notification);
        // this.oneSignal.handleNotificationReceived().subscribe((res) => {
        //   this.onPushReceived(res.payload)
        //   //  this.playRing();
        //   // console.log('notification recieved', res)
        //   // console.log('sound', res.payload.sound)
        // });
        // this.oneSignal.getIds().then((ids) => {
        //   // console.log("ids from one signal", ids);
        //   this.globals.deviceId = ids.userId
        // }).then(() => {
        //   this.oneSignal.setSubscription(true);
        //   //  this.listenForNotification();
        // }).catch(error => console.error("onesginal error", error));
        // this.oneSignal.setSubscription(true);
        // this.oneSignal.endInit();
      }
      //  this.getOrdersTemp()
      // this.OrderList()
      //  this.OrderList("1202976")
      this.showItems();
    });
    // one signal ends...................................
  }

  ngOnInit(): void {
    this.branches =
      localStorage.getItem('isMainBusiness') === 'true' ? true : false;
    const userData = JSON.parse(localStorage.getItem('user'));
    if (userData) {
      this.name = userData;
    }

    // Subscribe to changes in user data
    this.userDataService.userData.subscribe((data) => {
      if (data) {
        this.name = data;
        this.branches =
          localStorage.getItem('isMainBusiness') === 'true' ? true : false;
      }
    });
  }

  //   ionViewWillEnter(){
  //  //this.name = JSON.parse(localStorage.getItem('user'));

  //  console.log('hello name is view is', this.name.name);
  //   }

  // initializePushToken() {

  // }
  async stockout_items() {
    const modal = await this.modalCtrl.create({
      component: StockOutPage,
      // componentProps: { value: 123 }
    });

    await modal.present();

    const data = await modal.onDidDismiss();
  }

  checkBluetooth() {
    if (this.platform.is('android')) {
      this.androidP
        .checkPermission(this.androidP.PERMISSION.BLUETOOTH_CONNECT)
        .then(
          (res) => {
            console.log('checking permission BLUETOOTH_CONNECT', res);
            if (res.hasPermission) {
              console.log('has bluetooth');
              return true;
            } else {
              this.androidP
                .requestPermission(this.androidP.PERMISSION.BLUETOOTH_CONNECT)
                .then((res) => {
                  console.log('granted else BLUETOOTH_CONNECT ,', res);
                  // this.getPrinters()

                  // this.androidP.checkPermission(this.androidP.PERMISSION.BLUETOOTH).then(res=>{
                  //   console.log('bluetooth',res)
                  // },err=>{
                  //   this.androidP.requestPermission(this.androidP.PERMISSION.BLUETOOTH_CONNECT).then(res=>{
                  //     this.getPrinters()
                  //   })
                  // })
                  // return true
                });
            }
          },
          (err) => {
            this.androidP
              .requestPermission(this.androidP.PERMISSION.BLUETOOTH_CONNECT)
              .then((res) => {
                console.log('granted in error,', res);
                // this.getPrinters()
                // return true
              });
          }
        );
      //2nd

      this.androidP
        .checkPermission(this.androidP.PERMISSION.BLUETOOTH_SCAN)
        .then(
          (res) => {
            console.log('checking permission BLUETOOTH_SCAN', res);
            if (res.hasPermission) {
              console.log('has bluetooth');
              return true;
            } else {
              this.androidP
                .requestPermission(this.androidP.PERMISSION.BLUETOOTH_SCAN)
                .then((res) => {
                  console.log('granted else BLUETOOTH_SCAN ,', res);
                  // this.getPrinters()

                  // this.androidP.checkPermission(this.androidP.PERMISSION.BLUETOOTH).then(res=>{
                  //   console.log('bluetooth',res)
                  // },err=>{
                  //   this.androidP.requestPermission(this.androidP.PERMISSION.BLUETOOTH_CONNECT).then(res=>{
                  //     this.getPrinters()
                  //   })
                  // })
                  // return true
                });
            }
          },
          (err) => {
            this.androidP
              .requestPermission(this.androidP.PERMISSION.BLUETOOTH_SCAN)
              .then((res) => {
                console.log('granted in error,', res);
                // this.getPrinters()
                // return true
              });
          }
        );
      //3rd

      this.androidP
        .checkPermission(this.androidP.PERMISSION.BLUETOOTH_ADVERTISE)
        .then(
          (res) => {
            console.log('checking permission BLUETOOTH_ADVERTISE', res);
            if (res.hasPermission) {
              console.log('has bluetooth');
              return true;
            } else {
              this.androidP
                .requestPermission(this.androidP.PERMISSION.BLUETOOTH_ADVERTISE)
                .then((res) => {
                  console.log('granted else BLUETOOTH_ADVERTISE ,', res);
                  // this.getPrinters()

                  // this.androidP.checkPermission(this.androidP.PERMISSION.BLUETOOTH).then(res=>{
                  //   console.log('bluetooth',res)
                  // },err=>{
                  //   this.androidP.requestPermission(this.androidP.PERMISSION.BLUETOOTH_CONNECT).then(res=>{
                  //     this.getPrinters()
                  //   })
                  // })
                  // return true
                });
            }
          },
          (err) => {
            this.androidP
              .requestPermission(this.androidP.PERMISSION.BLUETOOTH_ADVERTISE)
              .then((res) => {
                console.log('granted in error,', res);
                // this.getPrinters()
                // return true
              });
          }
        );

      //4th
      this.androidP.checkPermission(this.androidP.PERMISSION.BLUETOOTH).then(
        (res) => {
          console.log('checking permission bluetooth', res);
          if (res.hasPermission) {
            console.log('has bluetooth');
            return true;
          } else {
            this.androidP
              .requestPermission(this.androidP.PERMISSION.BLUETOOTH)
              .then((res) => {
                console.log('granted else BLUETOOTH ,', res);
                // this.getPrinters()

                // this.androidP.checkPermission(this.androidP.PERMISSION.BLUETOOTH).then(res=>{
                //   console.log('bluetooth',res)
                // },err=>{
                //   this.androidP.requestPermission(this.androidP.PERMISSION.BLUETOOTH_CONNECT).then(res=>{
                //     this.getPrinters()
                //   })
                // })
                // return true
              });
          }
        },
        (err) => {
          this.androidP
            .requestPermission(this.androidP.PERMISSION.BLUETOOTH)
            .then((res) => {
              console.log('granted in error BLUETOOTH,', res);
              // this.getPrinters()
              // return true
            });
        }
      );
    }
  }
  logout() {
    clearInterval(this.globals.ordersInterval);
    localStorage.removeItem('isLogin');
    localStorage.removeItem('user');
    localStorage.removeItem('res_id');
    localStorage.removeItem('user_type');
    localStorage.removeItem('name');
    localStorage.removeItem('chatEnable');
    localStorage.removeItem('phoneNumber');
    localStorage.clear();
    // will clear all saved data in native storage......
    this.nativeStorage.clear();

    this.nav.navigateRoot('/login');
  }

  // private onPushReceived(payload: OSNotificationPayload) {
  //   // console.log('notfication recieved', payload)

  // }
  async stopTakingOrders(action, menuReady) {
    const alert = await this.alertCtrl.create({
      header: 'Alert!',
      message: 'Are you sure to stop taking online orders',
      buttons: [
        {
          text: 'No, Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
            console.log('Confirm Cancel: blah');
          },
        },
        {
          text: 'Yes, Stop',
          handler: () => {
            console.log('Confirm Okay');
            this.selectDateForStopingOrders(action, menuReady);
          },
        },
      ],
    });

    await alert.present();
  }
  async selectDateForStopingOrders(action, menuReady) {
    const modal = await this.modalCtrl.create({
      component: DateSelectionPage,
      componentProps: { value: 123 },
    });

    await modal.present();

    const data = await modal.onDidDismiss();
    console.log(data);

    if (data.data) {
      const selectedDate = data.data.selectedDate;
      const selectedTime = data.data.selectedTime;
      let date = `${selectedDate} ${selectedTime}`;
      this.go_to_ready_menu(action, menuReady, date);
    }
  }

  go_to_ready_menu(action, menu_ready, date?) {
    console.log('eeee', this.globals.ready_menu);

    this.globals.rest_id = localStorage.getItem('res_id');
    let response = this.server.go_ready_menu(menu_ready, action, date);
    response.subscribe(async (res) => {
      // console.log('accepting orders', res)
      if (res.status == true) {
        this.globals.ready_menu = res.menu_ready;
      }

      if (!this.globals.ready_menu) {
        this.globals.showToast(
          'Online orders are turned off. Please turn on Acepting Online Orders check to receive orders via app or website.',
          'danger'
        );
      } else {
        const toast = await this.toastController.create({
          animated: true,
          color: 'success',
          cssClass: 'toast-success',
          duration: 1000,
          keyboardClose: true,
          message: 'Online Orders Enabled',
          mode: 'ios',
          position: 'bottom',
        });
        toast.present();
      }
    });
  }
  // Message Print
  // getPrintingArray(){
  //   console.log("message printing is running")
  //   if (this.globals.address_printer == undefined || this.globals.address_printer == null) {
  //     // this.globals.showToast('No printers found. Go to settings and add a printer', 'warning')
  //   }
  //   else {
  //   if (localStorage.getItem("printedMessageArray") && localStorage.getItem("printedMessageArray") != 'null') {
  //     this.globals.printedMessage = JSON.parse(localStorage.getItem("printedMessageArray"))
  //   }
  //   console.log("global.printedMessage.....",this.globals.printedMessage)
  //   console.log("global.printedMessage length.....?",this.globals.printedMessage.length)

  //   this.AutoPrintMessage();
  // }
  // }

  // AutoPrintMessage(){
  //   var dateFormat = moment().format('YYYY-MM-DD');
  //   this.server.getMessage(dateFormat,dateFormat).subscribe(res=>{
  //     console.log('message res ',res)
  //     this.messageArray = res;

  //     if(this.messageArray.length > 0){

  //     this.messageArray.forEach(msg => { //3 msgs
  //       if(this.globals.printedMessage.length > 0){
  //       this.globals.printedMessage.forEach(printed => { //2 printed
  //         if(msg.id === printed.id){
  //           console.log('already printed',msg.id)

  //         }else{
  //           console.log("ids not match ... new print")

  //           this.ServicePrint.printMessage(msg, true);

  //           // this.globals.showToast(msg.Body , 'primary')
  //         }
  //       });
  //     }else{
  //       console.log("if local storage is empty...")
  //       // this.ServicePrint.printMessage(msg, true);

  //           this.ServicePrint.printMessage(msg, true);
  //       // this.globals.showToast(msg.Body , 'primary')
  //     }
  //     });
  //     }
  //   },err=>{
  //   })
  // }

  proceedGetOrders() {
    // console.log('printer adress', this.globals.address_printer)
    if (
      this.globals.address_printer == null ||
      this.globals.address_printer == undefined
    ) {
      if (this.showToastOnce == false) {
        this.showToastOnce = true;
        this.globals.showToast(
          'No printer connected. for printing orders Go to settings to add or turn off auto printing from settings',
          'danger'
        );
      }
      // return;
    } else {
      let isAnyPrinter = JSON.parse(localStorage.getItem('noPrinter'));
      // console.log('no printer ', isAnyPrinter)
      if (isAnyPrinter != null && isAnyPrinter != undefined) {
        this.globals.noPrinter = isAnyPrinter;
      }
    }
    // else{

    // }

    // status='Accepted';
    let response = this.server.getOrders(
      this.globals.busniess_id,
      '0',
      'pending'
    );
    response.subscribe(
      (res) => {
        console.log('pending orders to print ', res);
        this.globals.order_count = res.pending_orders;
        this.globals.pendingOrders = res.orders;
        this.response = res;
        if (res.success == true) {
          this.pendingOrders = [...res.orders]; //append new orders to list

          this.printingQueue = []; //clear queue everytime get data. no repeatation
          this.printingQueue = [...this.pendingOrders]; //append orders to queue
          if (this.printingQueue.length > 0) {
            this.globals.ordersPrinted.forEach((order_number) => {
              this.printingQueue = this.printingQueue.filter((item) => {
                return item.order_number != order_number;
              });
            });

            var print = this.printingQueue.pop();
            if (print) {
              this.flag = true;
              // console.log('something to print', print)
              // this.OrderList(this.res_id, 1, '', false);
              if (
                this.globals.ordersPrinted.indexOf(print.order_number) == -1 &&
                !this.globals.noPrinter
              ) {
                console.log('has something to print');
                this.ServicePrint.bluetooth_printing_segmentation(print);
                if (this.globals.copiesToprint > 1) {
                  for (let a = 1; a < this.globals.copiesToprint; a++) {
                    setTimeout(() => {
                      print['printType'] = 'Duplicate';
                      this.ServicePrint.bluetooth_printing_segmentation(print);
                    }, a * 10000);
                  }
                }
              } else {
                // console.log("permission denied to print. check settings", this.globals.noPrinter);
              }
            } else {
              this.flag = false;
            }
          } else {
          }
        }
      },
      (err) => {}
    );
  }
  getOrders() {
    this.nativeStorage.getItem('printer').then(
      (res) => {
        if (res != undefined && res != null) {
          // this.printerData=res;
          // console.log('printer saved in storage', res)
          this.globals.address_printer = res.address;
        }
        this.proceedGetOrders();
        // this.getPrintingArray();
      },
      (err) => {
        // native storage not present
        this.proceedGetOrders();
        // this.getPrintingArray();
      }
    );

    // native storage ends here

    // ......................
  }
  prepareToPrint() {
    var tab = '    ';
    let receipt = '';
    receipt += commands.HARDWARE.HW_INIT;
    receipt += commands.TEXT_FORMAT.TXT_4SQUARE;
    receipt += commands.TEXT_FORMAT.TXT_ALIGN_LT;
    receipt += this.globals.name.toUpperCase();
    receipt += commands.EOL;
    receipt += commands.TEXT_FORMAT.TXT_NORMAL;
    receipt += commands.HORIZONTAL_LINE.HR2_58MM;
    receipt += commands.TEXT_FORMAT.TXT_ALIGN_LT;
    receipt += commands.EOL;
    receipt += 'Order#:' + this.Orderdata.order_number;
    receipt += commands.EOL;
    receipt += 'Payment method:' + this.Orderdata.payment_method;
    receipt += commands.EOL;
    receipt += 'Order via:' + this.Orderdata.order_via;
    receipt += commands.EOL;
    receipt += 'Status:' + this.Orderdata.status;
    receipt += commands.EOL;
    receipt += commands.EOL;
    receipt += 'Customer Id:' + this.Orderdata.customer_id;
    receipt += commands.EOL;
    receipt += 'Customer Name:' + this.Orderdata.customer_name;
    receipt += commands.EOL;
    receipt += 'Customer phone:' + this.Orderdata.customer_phone;
    receipt += commands.EOL;
    receipt += commands.TEXT_FORMAT.TXT_ALIGN_LT;
    receipt += 'Date: ' + this.date;
    receipt += commands.EOL;
    receipt += 'Invoice created:' + this.Orderdata.order_placed_time;
    receipt += commands.EOL;

    receipt += commands.HORIZONTAL_LINE.HR2_58MM;
    receipt += commands.EOL;
    receipt += commands.TEXT_FORMAT.TXT_BOLD_ON;
    receipt +=
      'Item Name' +
      tab +
      '' +
      tab +
      '' +
      tab +
      '' +
      tab +
      '' +
      tab +
      'Qty' +
      tab +
      tab +
      'Amount';
    receipt += commands.EOL;
    receipt += commands.HORIZONTAL_LINE.HR2_58MM;
    receipt += commands.EOL;
    this.Orderdata.order_data.forEach((items) => {
      var tabs = '';
      if (items.menuItem.length < 25) {
        for (var i = 0; i <= 25 - items.menuItem.length; i++) {
          tabs += ' ';
        }
      } else {
        tabs = commands.EOL + tab + '' + tab + '' + tab + '' + tab;
      }
      receipt +=
        items.menuItem +
        tabs +
        tab +
        items.quantity +
        tab +
        tab +
        '$' +
        items.totalPrice;

      receipt += commands.EOL;
      receipt += commands.HORIZONTAL_LINE.HR3_58MM;
      receipt += commands.EOL;
    });

    receipt += commands.TEXT_FORMAT.TXT_NORMAL;
    receipt += commands.TEXT_FORMAT.TXT_ALIGN_RT;
    receipt += 'Sub Total:$' + this.ProductsTotal;
    receipt += commands.EOL;
    receipt += 'Tax:$' + this.CartTaxDisplay;
    receipt += commands.HORIZONTAL_LINE.HR2_58MM;
    receipt += commands.TEXT_FORMAT.TXT_4SQUARE;
    receipt += commands.TEXT_FORMAT.TXT_ALIGN_RT;
    receipt += 'Total:$' + this.TotalDisplay;
    receipt += commands.EOL;
    receipt += commands.TEXT_FORMAT.TXT_NORMAL;
    receipt += commands.HORIZONTAL_LINE.HR_58MM;
    receipt += commands.EOL;
    //secure space on footer
    receipt += commands.EOL;
    receipt += commands.EOL;
    receipt += commands.EOL;

    this.print(this.globals.printerDevice.address, receipt);
  }
  async print(device, data) {
    // console.log('Device mac: ', device);
    // console.log('Data: ', data);
    // const loading = await this.loadingController.create({
    //   message: 'Printing...',
    // });
    // await loading.present();

    const toast = await this.toastController.create({
      message: 'Printing... Please wait',
      buttons: [
        {
          text: '',
          side: 'end',
          icon: 'close',
          cssClass: 'cancelBTN',
          role: 'cancel',
          handler: () => {
            console.log('Cancel clicked');
          },
        },
      ],
      // color:''
    });
    toast.present();

    this.printer.connectBluetooth(device).subscribe(
      (status) => {
        // console.log(status);
        this.printer
          .printData(this.noSpecialChars(data))
          .then((printStatus) => {
            // console.log(printStatus);
            // loading.dismiss();
            toast.dismiss();
            this.globals.showToast('Success!', 'success');
            this.printer.disconnectBluetooth();
          })
          .catch((error) => {
            // console.log(error);
            // loading.dismiss();
            toast.dismiss();

            this.globals.showToast('Error', 'danger');
          });
      },
      (error) => {
        // console.log(error);
        // loading.dismiss();
        toast.dismiss();

        this.globals.showToast('Error. not properly configured', 'danger');
      }
    );
  }
  financial(x) {
    // console.log(x);
    if (x != 0) {
      return Number.parseFloat(x).toFixed(2);
    } else {
      return '0.00';
    }
  }
  noSpecialChars(string) {
    var translate = {
        à: 'a',
        á: 'a',
        â: 'a',
        ã: 'a',
        ä: 'a',
        å: 'a',
        æ: 'a',
        ç: 'c',
        è: 'e',
        é: 'e',
        ê: 'e',
        ë: 'e',
        ì: 'i',
        í: 'i',
        î: 'i',
        ï: 'i',
        ð: 'd',
        ñ: 'n',
        ò: 'o',
        ó: 'o',
        ô: 'o',
        õ: 'o',
        ö: 'o',
        ø: 'o',
        ù: 'u',
        ú: 'u',
        û: 'u',
        ü: 'u',
        ý: 'y',
        þ: 'b',
        ÿ: 'y',
        ŕ: 'r',
        À: 'A',
        Á: 'A',
        Â: 'A',
        Ã: 'A',
        Ä: 'A',
        Å: 'A',
        Æ: 'A',
        Ç: 'C',
        È: 'E',
        É: 'E',
        Ê: 'E',
        Ë: 'E',
        Ì: 'I',
        Í: 'I',
        Î: 'I',
        Ï: 'I',
        Ð: 'D',
        Ñ: 'N',
        Ò: 'O',
        Ó: 'O',
        Ô: 'O',
        Õ: 'O',
        Ö: 'O',
        Ø: 'O',
        Ù: 'U',
        Ú: 'U',
        Û: 'U',
        Ü: 'U',
        Ý: 'Y',
        Þ: 'B',
        Ÿ: 'Y',
        Ŕ: 'R',
      },
      translate_re =
        /[àáâãäåæçèéêëìíîïðñòóôõöøùúûüýþßàáâãäåæçèéêëìíîïðñòóôõöøùúûýýþÿŕŕÀÁÂÃÄÅÆÇÈÉÊËÌÍÎÏÐÑÒÓÔÕÖØÙÚÛÜÝÞßÀÁÂÃÄÅÆÇÈÉÊËÌÍÎÏÐÑÒÓÔÕÖØÙÚÛÝÝÞŸŔŔ]/gim;
    return string.replace(translate_re, function (match) {
      return translate[match];
    });
  }
  playRingtone(res) {
    console.log(
      'ring',
      this.globals.continuousRing,
      this.globals.order_count,
      this.globals
    );
    if (this.globals.continuousRing == 'true') {
      //continue ringing
      if (this.globals.order_count > 0) {
        console.log('ring continue');
        this.ringtone.continuesRing();
      } else {
        console.log('ring stop');
        this.ringtone.stopRing();
      }
    } else {
      //ring once
      console.log('ring once');
      this.ringtone.stopRing();
      if (res.success == true && this.globals.order_count > 0) {
        console.log('ring continue once');
        this.ringtone.ringOnce();
      }
    }
  }
  total() {
    this.ProductsTotal = 0;
    this.CartTax = 0;
    this.pos_items_discount = 0;
    var ItemDiscount = 0;
    var ItemDiscountedValue = 0;
    for (let sub of this.Orderdata.order_data) {
      ItemDiscount = 0;
      let CalculatedTax = 0;
      this.ProductsTotal += Number(sub.basePrice) * Number(sub.quantity);
      if (sub.discount_value) {
        sub.discount_type == 'cash'
          ? (ItemDiscount += Number(sub.discount_value) * Number(sub.quantity))
          : sub.discount_type == 'perc'
          ? (ItemDiscount +=
              (Number(sub.discount_value) / 100) *
              Number(sub.basePrice) *
              Number(sub.quantity))
          : console.log('discount neither cash nor percent');
        ItemDiscountedValue =
          Number(sub.basePrice) - Number(ItemDiscount) * Number(sub.quantity);
      } else {
        ItemDiscountedValue = Number(sub.basePrice) * Number(sub.quantity);
      }

      CalculatedTax =
        (Number(this.Orderdata.tax) / 100) *
        Number(ItemDiscountedValue) *
        Number(sub.quantity);
      this.CartTax += CalculatedTax;
      // this.global.pos_items_discount += ItemDiscount;
      sub.CalculatedDiscount = this.financial(ItemDiscount);
      sub.CalculatedTax = this.financial(CalculatedTax);
    }
    this.CartTax = Math.round(this.CartTax * 100) / 100;
    this.Total =
      Math.round(
        (this.ProductsTotal - this.pos_items_discount + this.CartTax) * 100
      ) / 100;
    this.DiscountDisplay = this.financial(this.pos_items_discount);
    this.CartTaxDisplay = this.financial(this.CartTax);
    this.TotalDisplay = this.financial(this.Total);
    // this.global.tax_printer = this.CartTaxDisplay;
  }
  standardAlert() {
    // console.log('standard')
  }
  fullScreenAlert() {
    // console.log('fullscreen alerts')
  }
  loadsounds() {
    this.nativeAudio.preloadSimple('sound1', 'assets/sound/sound1.mp3');
    this.nativeAudio.preloadSimple('sound2', 'assets/sound/sound2.mp3');
    this.nativeAudio.preloadSimple('sound3', 'assets/sound/sound3.mp3');
    this.nativeAudio.preloadSimple('sound4', 'assets/sound/sound4.mp3');
  }
  showItems(link?, isPos?) {
    if (isPos == true) {
      this.globals.posReports = true;
    } else {
      this.globals.posReports = false;
    }
    // this.showOtherOrders = true;
    // Get the container element
    var btnContainer = document.getElementById('nav');

    // Get all buttons with class="btn" inside the container
    var btns = btnContainer.getElementsByClassName('menuitem');

    if (link) {
      if (isPos == false) {
        this.router.navigateByUrl(link, { replaceUrl: true });
      } else {
        this.router.navigateByUrl(link + '/pos', { replaceUrl: true });
      }
    }
  }
  AdvancedSettings() {
    if (localStorage.getItem('ASPendingOrders') != null) {
      this.globals.ASPendingOrders = JSON.parse(
        localStorage.getItem('ASPendingOrders')
      );
    }
    if (localStorage.getItem('AScancelledOrders') != null) {
      this.globals.AScancelledOrders = JSON.parse(
        localStorage.getItem('AScancelledOrders')
      );
    }
    if (localStorage.getItem('ASadjustedOrders') != null) {
      this.globals.ASadjustedOrders = JSON.parse(
        localStorage.getItem('ASadjustedOrders')
      );
    }
    if (localStorage.getItem('ASMenu') != null) {
      this.globals.ASMenu = JSON.parse(localStorage.getItem('ASMenu'));
    }
    if (localStorage.getItem('ASStockout') != null) {
      this.globals.ASStockout = JSON.parse(localStorage.getItem('ASStockout'));
    }
    if (localStorage.getItem('ASReports') != null) {
      this.globals.ASReports = JSON.parse(localStorage.getItem('ASReports'));
    }
    if (localStorage.getItem('ASPOSReports') != null) {
      this.globals.ASPOSReports = JSON.parse(
        localStorage.getItem('ASPOSReports')
      );
    }
    if (localStorage.getItem('ASBranches') != null) {
      this.globals.ASBranches = JSON.parse(localStorage.getItem('ASBranches'));
    }
    if (localStorage.getItem('ASKitchenDisplay') != null) {
      this.globals.ASKitchenDisplay = JSON.parse(
        localStorage.getItem('ASKitchenDisplay')
      );
    }
    if (localStorage.getItem('ASChat') != null) {
      this.globals.ASChat = JSON.parse(localStorage.getItem('ASChat'));
    }
    if (localStorage.getItem('ASLoyalty') != null) {
      this.globals.ASLoyalty = JSON.parse(localStorage.getItem('ASLoyalty'));
    }
  }

  public openWithInAppBrowser() {
    let target = '_blank';

    let username = localStorage.getItem('username');
    let password = localStorage.getItem('primary_key');
    //  +'&page='
    let url =
      this.globals.portalUrl +
      'business_login_via_app?username=' +
      username +
      '&password=' +
      password;
    this.theInAppBrowser.create(url, target);
  }
}
